import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import CheckIcon from "@material-ui/icons/Check";
import { useLocation } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import googleButton from "../assets/google-play-badge.png";

export const DeleteAccountPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      Please send an email with subject "Delete" and mention your email for
      deleting your account & data. To: <div></div>
      <b style={{ fontSize: 25 }}>attendance.qr.app@gmail.com</b>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: "100vw",
      minHeight: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      padding: 16,
    },
    paper: { backgroundColor: "rgba(255,255,255, 0.07)", padding: 16 },
    icon: { fontSize: 70, marginBottom: 10, marginTop: 10 },
    link: {},
    image: { width: 250 },
    divider: { marginTop: 20, marginBottom: 20, width: 250 },
    web: { fontWeight: 300 },
  })
);
