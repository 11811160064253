import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import CheckIcon from "@material-ui/icons/Check";
import { useLocation } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import googleButton from "../assets/google-play-badge.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: "100vw",
      minHeight: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      padding: 16,
    },
    paper: { backgroundColor: "rgba(255,255,255, 0.07)", padding: 16 },
    icon: { fontSize: 70, marginBottom: 10, marginTop: 10 },
    link: {},
    image: { width: 250 },
    divider: { marginTop: 20, marginBottom: 20, width: 250 },
    web: { fontWeight: 300 },
  })
);

export const JoinSuccessPage = () => {
  const classes = useStyles();

  const location: any = useLocation();
  const { joinedClassroomName, joinerName } = location.state;
  return (
    <div className={classes.wrapper}>
      <Typography variant="h5">Joined {joinedClassroomName}</Typography>
      <CheckIcon className={classes.icon} />
      <Paper className={classes.paper}>
        <Typography variant="body1">
          As "{joinerName}".
        </Typography>
      </Paper>

      <Divider className={classes.divider} />

      <Typography variant="body1" component="h2">
        Try App
      </Typography>
      <a
        className={classes.link}
        rel="noreferrer"
        href="https://play.google.com/store/apps/details?id=com.attendanceqr"
      >
        <img
          className={classes.image}
          alt="Get it on Google Play"
          src={googleButton}
        />
      </a>
    </div>
  );
};
