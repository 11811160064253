import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import googleButton from "../assets/google-play-badge.png";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles({
  wrapper: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  announce: {
    padding: 24,
  },
  link: {},
  image: { width: 250 },
  divider: { marginTop: 20, marginBottom: 20 },
  web: { fontWeight: 300 },
});
export const HomePage = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.announce}>
        <Typography variant="body1" component="h2">
          Currently available in Play Store
        </Typography>
        <a
          className={classes.link}
          rel="noreferrer"
          href="https://play.google.com/store/apps/details?id=com.attendanceqr"
        >
          <img
            className={classes.image}
            alt="Get it on Google Play"
            src={googleButton}
          />
        </a>
      </div>
    </div>
  );
};
