import { useHistory, useParams } from "react-router-dom";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useState } from "react";

import CryptoES from "crypto-es";
import { useDispatch, useSelector } from "react-redux";
import {
  joinSessionThunk,
  selectJoinedClassroomName,
  selectJoinSessionError,
  selectJoinSessionStatus,
} from "../redux/joinSessionSlice/joinSessionSlice";
import Sentry from "react-activity/dist/Sentry";
import Typography from "@material-ui/core/Typography";
import ClearIcon from "@material-ui/icons/Clear";
import Paper from "@material-ui/core/Paper";
import { useEffect } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: "100vw",
      minHeight: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      padding: 16,
    },
    form: { display: "flex", flexDirection: "column" },
    paper: { backgroundColor: "rgba(255,255,255, 0.07)", padding: 16 },
    textAs: { marginBottom: 16, marginTop: 16 },
    input: {
      width: 250,
      [theme.breakpoints.up("sm")]: {
        width: 400,
      },
      [theme.breakpoints.up("md")]: {
        width: 450,
      },
    },
    button: {
      width: 250,
      marginTop: 16,
      [theme.breakpoints.up("sm")]: {
        width: 400,
      },
      [theme.breakpoints.up("md")]: {
        width: 450,
      },
    },
  })
);

export const JoinPage = () => {
  const status = useSelector(selectJoinSessionStatus);
  const error = useSelector(selectJoinSessionError);
  const joinedClassroomName = useSelector(selectJoinedClassroomName);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [joinerName, setJoinerName] = useState<string>("");
  const [joinerPhone, setJoinerPhone] = useState<string>("");
  const [joinerMail, setJoinerMail] = useState<string>("");

  let { timestamp, session }: any = useParams();
  let history = useHistory();
  useEffect(() => {
    if (status === "success") {
      history.replace("success", {
        joinerName: joinerName,
        joinedClassroomName: joinedClassroomName,
      });
    }
  }, [history, joinedClassroomName, joinerName, status]);
  const decodedTimestampPre = timestamp.replace(/-y/g, "+");
  const decodedTimestamp = decodedTimestampPre.replace(/-x/g, "/");
  const decrypted = parseInt(
    CryptoES.AES.decrypt(decodedTimestamp, "ummagumma421").toString(
      CryptoES.enc.Utf8
    ),
    10
  );

  if (isNaN(decrypted) || decrypted < 1623407196567) {
    return (
      <div className={classes.wrapper}>
        <Typography variant="h5" gutterBottom>
          Error
        </Typography>
        <Typography variant="body1">Can't recognize this URL</Typography>
      </div>
    );
  }
  const handleJoin = async (e: any) => {
    e.preventDefault();
    const currentDate = Math.round(Date.now() / 1000);
    if (joinerName && joinerName.length <= 50) {
      /* 
      // Test
      setInterval(() => {
        console.count("counter;")
        dispatch(
          joinSessionThunk({
            displayName: faker.name.findName(),
            sessionID: timestamp + "=" + session,
            currentDate: currentDate,
          })
        );
      }, 1600);
      */
      dispatch(
        joinSessionThunk({
          displayName: joinerName,
          sessionID: timestamp + "=" + session,
          currentDate: currentDate,
          additionalUserInfo: {
            name: joinerName,
            email: joinerMail,
            phone: joinerPhone,
          },
        })
      );
    } else {
      alert("Need a name less than 50 characters.");
    }
  };
  let content;
  if (status === "idle") {
    content = (
      <form onSubmit={handleJoin} className={classes.form}>
        <Button
          size="large"
          className={classes.button}
          variant="contained"
          type="submit"
        >
          Join
        </Button>
        <span className={classes.textAs}>as</span>
        <TextField
          className={classes.input}
          id="standard-basic"
          label="Name(*)"
          variant="outlined"
          onChange={(e) => setJoinerName(e.target.value)}
          inputProps={{ maxLength: 50 }}
        />

        <hr style={{ width: "100%", marginTop: 20, marginBottom: 20, opacity:0 }} />
        <span className={classes.textAs}>Additional Fields:</span>

        <TextField
          className={classes.input}
          id="standard-basic"
          label="Mail"
          variant="outlined"
          onChange={(e) => setJoinerMail(e.target.value)}
          inputProps={{ maxLength: 50 }}
        />
        <div style={{ marginTop: 15 }}></div>
        <TextField
          className={classes.input}
          id="standard-basic"
          label="Phone"
          variant="outlined"
          onChange={(e) => setJoinerPhone(e.target.value)}
          inputProps={{ maxLength: 50 }}
        />
      </form>
    );
  } else if (status === "loading") {
    content = <Sentry color="#fff" size={32} speed={1} animating={true} />;
  } else if (status === "error") {
    content = (
      <div>
        <Typography variant="h5">Failed to Join</Typography>
        <ClearIcon style={{ fontSize: 70, marginBottom: 10, marginTop: 10 }} />
        <Paper className={classes.paper}>
          <Typography variant="h5" gutterBottom>
            {error.errorTitle!}
          </Typography>
          <Typography variant="body1">{error.errorMessage!}</Typography>
        </Paper>
      </div>
    );
  }
  return <div className={classes.wrapper}>{content}</div>;
};
