import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyCDCApbdXfdbOPg3GH5Yq9jiNjUQ2oMWp0",
  authDomain: "attendanceqr-57846.firebaseapp.com",
  databaseURL: "https://attendanceqr-57846-default-rtdb.firebaseio.com",
  projectId: "attendanceqr-57846",
  storageBucket: "attendanceqr-57846.appspot.com",
  messagingSenderId: "758694989844",
  appId: "1:758694989844:web:766689d9ce4c6c16305701",
  measurementId: "G-277TDE4DPE",
};

initializeApp(firebaseConfig);

export const db = getDatabase();
export const firestore = getFirestore();
