import React from "react";
import "./App.css";

import { Switch, Route, useLocation } from "react-router-dom";
import { JoinPage } from "./pages/JoinPage";
import { HomePage } from "./pages/HomePage";
import { JoinSuccessPage } from "./pages/JoinSuccessPage";
import { PrivacyPage } from "./pages/PrivacyPage";
import { DeleteAccountPage } from "./pages/DeleteAccount";

function App() {
  const location = useLocation();
  return (
    <div className="App">
      <Switch>
        {location.state && (
          <Route exact path="/join/success">
            <JoinSuccessPage />
          </Route>
        )}
        <Route path="/join/:timestamp?=:session?">
          <JoinPage />
        </Route>
        <Route path="/privacy">
          <PrivacyPage />
        </Route>
        <Route path="/deleteAccount">
          <DeleteAccountPage />
        </Route>
        <Route path="/">
          <HomePage />
        </Route>
      </Switch>
      <div style={{ position: "absolute", bottom: 0, right: 0, padding: 5 }}>
        <a
          href="/privacy"
          style={{
            marginTop: "auto",
            color: "rgba(255,255,255,0.2)",
            textDecoration: "inherit",
          }}
        >
          Privacy
        </a>
      </div>
    </div>
  );
}

export default App;
