import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { firestore } from "../../firebase/firebase.utils";

import {
  collection,
  doc,
  runTransaction,
  Timestamp,
  arrayUnion,
} from "firebase/firestore";

const errorTemplates = {
  notFound: {
    errorTitle: "Not found",
    errorMessage: "Can't find the Session.",
  },
  limit: {
    errorTitle: "Full",
    errorMessage: "Session reached the maximum capacity.",
  },
  time: {
    errorTitle: "Time has expired.",
    errorMessage: "Attendance closed.",
  },
  alreadyIn: {
    errorTitle: "Failed",
    errorMessage: "Your name is already in the list.",
  },
};

interface JoinerAttributes {
  displayName: string;
  sessionID: string;
  currentDate: number;
  additionalUserInfo: { name: string; phone: string; email: string };
}

interface joinSessionError {
  errorTitle?: string;
  errorMessage?: string;
}

// Define a type for the slice state
interface joinSessionState {
  status: string;
  error: joinSessionError;
  classroomName: string;
}

export const joinSessionThunk = createAsyncThunk<
  any,
  JoinerAttributes,
  {
    rejectValue: joinSessionError;
  }
>("session/join", async (args, thunkApi) => {
  const { displayName, sessionID, currentDate, additionalUserInfo } = args;

  try {
    const sessionRef = doc(firestore, "activeSessions", sessionID);
    const participantsRef = doc(
      collection(firestore, "activeSessions", sessionID, "participants")
    );

    const transaction = await runTransaction(firestore, async (transaction) => {
      const sessionSnapshot = await transaction.get(sessionRef);
      if (!sessionSnapshot.exists()) {
        return thunkApi.rejectWithValue(errorTemplates.notFound);
      }
      const data = sessionSnapshot.data();
      if (data.dateStart.seconds + data.duration * 60 < currentDate) {
        return thunkApi.rejectWithValue(errorTemplates.time);
      }
      if (data.limitCount >= data.limit) {
        return thunkApi.rejectWithValue(errorTemplates.limit);
      }
      if (
        data.partiList &&
        data.partiList.length &&
        data.partiList.includes(displayName)
      ) {
        return thunkApi.rejectWithValue(errorTemplates.alreadyIn);
      }
      const newLimitCount = data.limitCount + 1;
      transaction.set(participantsRef, {
        displayName: displayName,
        role: "",
        isAnonymous: true,
        timestamp: Timestamp.now(),
      });
      transaction.update(sessionRef, {
        limitCount: newLimitCount,
        partiList: arrayUnion(displayName),
        partiListDetails: arrayUnion(additionalUserInfo),
      });
      return data.classroomName ? data.classroomName : "Unknown name";
    });
    return transaction;
  } catch (error) {
    return thunkApi.rejectWithValue({
      errorTitle: "Error",
      errorMessage: error.message,
    } as joinSessionError);
  }
});

// Define the initial state using that type
const initialState: joinSessionState = {
  status: "idle",
  error: {},
  classroomName: "",
};

export const joinSessionSlice = createSlice({
  name: "joinSession",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(joinSessionThunk.pending, (state, { payload }) => {
      state.status = "loading";
    });
    builder.addCase(joinSessionThunk.fulfilled, (state, { payload }) => {
      state.status = "success";
      state.classroomName = payload;
    });
    builder.addCase(joinSessionThunk.rejected, (state, action) => {
      state.status = "error";
      if (action.payload) {
        // Since we passed in `MyKnownError` to `rejectValue` in `updateUser`, the type information will be available here.
        state.error = {
          errorMessage: action.payload.errorMessage,
          errorTitle: action.payload.errorTitle,
        };
      } else {
        //@ts-ignore
        state.error = { errorTitle: action.error };
      }
    });
  },
});

// export const { x } = joinSessionSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectJoinSessionStatus = (state: RootState) =>
  state.joinSession.status;
export const selectJoinSessionError = (state: RootState) =>
  state.joinSession.error;
export const selectJoinedClassroomName = (state: RootState) =>
  state.joinSession.classroomName;

export default joinSessionSlice.reducer;
