import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import googleButton from "../assets/google-play-badge.png";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import { Container } from "@material-ui/core";

const useStyles = makeStyles({
  wrapper: {},
  announce: { marginTop: 0 },
  link: {},
  image: { width: 250 },
  divider: { marginTop: 20, marginBottom: 20 },
});
const lightTheme = createMuiTheme({ palette: { type: "light" } });
export const PrivacyPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.announce}>
        <Typography variant="h3" style={{ marginTop: 12, marginBottom: 12 }}>
          Privacy policy
        </Typography>
        <ThemeProvider theme={lightTheme}>
          <Container maxWidth="lg">
            <Paper style={{ padding: 10, textAlign: "left" }}>
              <Typography variant="caption">
                Effective date: October 19, 2021
              </Typography>
              <Divider className={classes.divider} />
              <Typography variant="body2">
                This page informs you of our policies regarding the collection,
                use, and disclosure of personal data when you use our Service
                and the choices you have associated with that data.
              </Typography>
              <br />
              <Typography variant="body2">
                We collect information to provide better services to all our
                users.
              </Typography>
              <br />
              <Typography variant="h6">
                Things you create or provide to us
              </Typography>
              <Typography variant="body2">
                When you sign in you provide us with personal information that
                includes name, gender, surname, profile picture, preffered
                languages, Google public ID, email and public informations that
                you share via service provider accounts(Google sign in etc.),
                plus "Usage Data".
                <br />
                When you are not signed in we collect Usage Data and your name
                if you assign a name when using our services.
                <br />
                <br />
                The Service Providers We use may have access to Your Personal
                Data. These third-party vendors collect, store, use, process and
                transfer information about Your activity on Our Service in
                accordance with their Privacy Policies.
                <br />
                (Service Providers: Analytics, Google Admob, Google Cloud
                Services, Google AdSense. Thoose help us to give you a better
                service.)
              </Typography>
              <br />
              <Typography variant="h6">Usage Data</Typography>
              <Typography>
                We may also collect information that your browser sends whenever
                you visit our Service or when you access the Service by or
                through a mobile device ("Usage Data").
                <br />
                <br />
                This Usage Data may include information such as your computer's
                Internet Protocol address (e.g. IP address), browser type,
                browser version, the pages of our Service that you visit, the
                time and date of your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data.
                <br />
                <br />
                When you access the Service by or through a mobile device, this
                Usage Data may include information such as the type of mobile
                device you use, your mobile device unique ID, the IP address of
                your mobile device, your mobile operating system, the type of
                mobile Internet browser you use, unique device identifiers and
                other diagnostic data.
              </Typography>
              <Divider />
              <br />
              We use the collected data for various purposes:
              <br />
              <br />
              <Typography variant="caption">
                To customize the advertising and content you see
                <br />
                To provide and maintain our Service
                <br />
                To notify you about changes to our Service To allow you to
                participate in interactive features of our Service when you
                choose to do so
                <br />
                To provide customer support
                <br />
                To gather analysis or valuable information so that we can
                improve our Service <br />
                To monitor the usage of our Service <br />
                To detect, prevent and address technical issues <br />
                To provide you with news, special offers and general information
                about other goods, services and events which we offer that are
                similar to those that you have already purchased or enquired
                about unless you have opted not to receive such information{" "}
                <br />
                To conduct research, and provide aggregated, anonymized
                reporting about our general user community for internal and
                external clients.
              </Typography>
              <br />
              <br />
              <Typography variant="h6">
                Retention of Your Personal Data
              </Typography>
              <Typography variant="body2">
                We will retain Your Personal Data only for as long as is
                necessary for the purposes set out in this Privacy Policy. We
                will retain and use Your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
                <br />
                <br />
                We will also retain Usage Data for internal analysis purposes.
                Usage Data is generally retained for a shorter period of time,
                except when this data is used to strengthen the security or to
                improve the functionality of Our Service, or We are legally
                obligated to retain this data for longer time periods.
              </Typography>
              <br />
              <br />
              <Typography variant="h6">
                Transfer of Your Personal Data
              </Typography>
              <Typography variant="body2">
                Your information, including Personal Data, is processed at the
                our operating offices and in any other places where the parties
                involved in the processing are located. It means that this
                information may be transferred to — and maintained on —
                computers located outside of Your state, province, country or
                other governmental jurisdiction where the data protection laws
                may differ than those from Your jurisdiction.
                <br />
                <br />
                Your consent to this Privacy Policy followed by Your submission
                of such information represents Your agreement to that transfer.
                <br />
                <br />
                We will take all steps reasonably necessary to ensure that Your
                data is treated securely and in accordance with this Privacy
                Policy and no transfer of Your Personal Data will take place to
                an organization or a country unless there are adequate controls
                in place including the security of Your data and other personal
                information.
              </Typography>
              <br />
              <br />
              <Typography variant="h6">
                Disclosure of Your Personal Data
              </Typography>
              <Typography>
                We may disclose your Personal Data in the good faith belief that
                s uch actionis necessary to: To comply with a legal obligation,
                to protect and defend our rights or property, to prevent or
                ignvestigate possible wrondoing in connection with the Service,
                to protect the personal safety of users of the Service or the
                public, to protect against legal liability
              </Typography>
              <br />
              <br />
              <Typography variant="h6">GDPR Privacy</Typography>
              <Typography variant="body2">
                We may process Personal Data under the following conditions:
                <br />
                <br />
                Consent: You have given Your consent for processing Personal
                Data for one or more specific purposes.
                <br />
                Performance of a contract: Provision of Personal Data is
                necessary for the performance of an agreement with You and/or
                for any pre-contractual obligations thereof.
                <br />
                Legal obligations: Processing Personal Data is necessary for
                compliance with a legal obligation to which we are the subject.
                <br />
                Vital interests: Processing Personal Data is necessary in order
                to protect Your vital interests or of another natural person.
                <br />
                Public interests: Processing Personal Data is related to a task
                that is carried out in the public interest or in the exercise of
                official authority vested with us.
                <br />
                Legitimate interests: Processing Personal Data is necessary for
                the purposes of the legitimate interests pursued by us.
                <br />
                <br />
                In any case, we will gladly help to clarify the specific legal
                basis that applies to the processing, and in particular whether
                the provision of Personal Data is a statutory or contractual
                requirement, or a requirement necessary to enter into a
                contract.
              </Typography>
              <br />
              <br />
              <Typography variant="h6">Your Rights under the GDPR</Typography>
              <Typography variant="body2">
                We undertake to respect the confidentiality of Your Personal
                Data and to guarantee You can exercise Your rights.
                <br />
                <br /> You have the right under this Privacy Policy, and by law
                if You are within the EU, to:
                <br />
                <br />
                Request access to Your Personal Data. The right to access,
                update or delete the information We have on You. Whenever made
                possible, you can access, update or request deletion of Your
                Personal Data directly within Your account settings section. If
                you are unable to perform these actions yourself, please contact
                Us to assist You. This also enables You to receive a copy of the
                Personal Data We hold about You.
                <br />
                Request correction of the Personal Data that We hold about You.
                You have the right to have any incomplete or inaccurate
                information We hold about You corrected.
                <br />
                Object to processing of Your Personal Data. This right exists
                where We are relying on a legitimate interest as the legal basis
                for Our processing and there is something about Your particular
                situation, which makes You want to object to our processing of
                Your Personal Data on this ground. You also have the right to
                object where We are processing Your Personal Data for direct
                marketing purposes.
                <br />
                Request erasure of Your Personal Data. You have the right to ask
                Us to delete or remove Personal Data when there is no good
                reason for Us to continue processing it.
                <br />
                Request the transfer of Your Personal Data. We will provide to
                You, or to a third-party You have chosen, Your Personal Data in
                a structured, commonly used, machine-readable format. Please
                note that this right only applies to automated information which
                You initially provided consent for Us to use or where We used
                the information to perform a contract with You.
                <br />
                Withdraw Your consent. You have the right to withdraw Your
                consent on using your Personal Data. If You withdraw Your
                consent, We may not be able to provide You with access to
                certain specific functionalities of the Service.
                <br />
                <br />
                You may exercise Your rights of access, rectification,
                cancellation and opposition by contacting Us. Please note that
                we may ask You to verify Your identity before responding to such
                requests. If You make a request, We will try our best to respond
                to You as soon as possible. <br />
                <br />
                You have the right to complain to a Data Protection Authority
                about Our collection and use of Your Personal Data. For more
                information, if You are in the European Economic Area (EEA),
                please contact Your local data protection authority in the EEA.
              </Typography>
              <br />
              <br />
              <Typography variant="h6">CCPA Privacy</Typography>
              <br />
              <br />
              <Typography variant="body2">
                This privacy notice section for California residents supplements
                the information contained in Our Privacy Policy and it applies
                solely to all visitors, users, and others who reside in the
                State of California.
              </Typography>
              <br />
              <br />
              <Typography variant="body2">
                We collect information that identifies, relates to, describes,
                references, is capable of being associated with, or could
                reasonably be linked, directly or indirectly, with a particular
                Consumer or Device. The following is a list of categories of
                personal information which we may collect or may have been
                collected from California residents within the last twelve (12)
                months.
                <br />
                <br /> Please note that the categories and examples provided in
                the list below are those defined in the CCPA. This does not mean
                that all examples of that category of personal information were
                in fact collected by Us, but reflects our good faith belief to
                the best of our knowledge that some of that information from the
                applicable category may be and may have been collected. For
                example, certain categories of personal information would only
                be collected if You provided such personal information directly
                to Us.
                <br />
                <br />
              </Typography>
              <br />
              <br />
              <Typography variant="h6">
                Categories of Personal Information Collected
              </Typography>
              <Typography variant="body2">
                <b>Category A: Identifiers.</b>
                <br />
                Examples: A real name, alias, postal address, unique personal
                identifier, online identifier, Internet Protocol address, email
                address, account name, driver's license number, passport number,
                or other similar identifiers.
                <br />
                <br />
                Collected: Yes.
                <br />
                <br />
                <b>
                  Category B: Personal information categories listed in the
                  California Customer Records statute (Cal. Civ. Code §
                  1798.80(e)).
                </b>
                <br />
                Examples: A name, signature, Social Security number, physical
                characteristics or description, address, telephone number,
                passport number, driver's license or state identification card
                number, insurance policy number, education, employment,
                employment history, bank account number, credit card number,
                debit card number, or any other financial information, medical
                information, or health insurance information. Some personal
                information included in this category may overlap with other
                categories.
                <br />
                <br />
                Collected: Yes.
                <br />
                <br />
                <b>
                  Category C: Protected classification characteristics under
                  California or federal law.
                </b>
                <br />
                Examples: Age (40 years or older), race, color, ancestry,
                national origin, citizenship, religion or creed, marital status,
                medical condition, physical or mental disability, sex (including
                gender, gender identity, gender expression, pregnancy or
                childbirth and related medical conditions), sexual orientation,
                veteran or military status, genetic information (including
                familial genetic information).
                <br />
                <br />
                Collected: No.
                <br />
                <br />
                <b>Category D: Commercial information.</b>
                <br />
                Examples: Records and history of products or services purchased
                or considered.
                <br />
                <br />
                Collected: Yes.
                <br />
                <br />
                <b>Category E: Biometric information.</b>
                <br />
                Examples: Genetic, physiological, behavioral, and biological
                characteristics, or activity patterns used to extract a template
                or other identifier or identifying information, such as,
                fingerprints, faceprints, and voiceprints, iris or retina scans,
                keystroke, gait, or other physical patterns, and sleep, health,
                or exercise data.
                <br />
                <br />
                Collected: No.
                <br />
                <br />
                <b>Category F: Internet or other similar network activity.</b>
                <br />
                Examples: Interaction with our Service or advertisement.
                <br />
                <br />
                Collected: Yes.
                <br />
                <br />
                <b>Category G: Geolocation data.</b>
                <br />
                Examples: Approximate physical location.
                <br />
                <br />
                Collected: No.
                <br />
                <br />
                <b>Category H: Sensory data.</b>
                <br />
                Examples: Audio, electronic, visual, thermal, olfactory, or
                similar information.
                <br />
                <br />
                Collected: No.
                <br />
                <br />
                <b>
                  Category I: Professional or employment-related information.
                </b>
                <br />
                Examples: Current or past job history or performance
                evaluations.
                <br />
                <br />
                Collected: No.
                <br />
                <br />
                <b>
                  Category J: Non-public education information (per the Family
                  Educational Rights and Privacy Act (20 U.S.C. Section 1232g,
                  34 C.F.R. Part 99)).
                </b>
                <br />
                Category J: Non-public education information (per the Family
                Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34
                C.F.R. Part 99)).
                <br />
                <br />
                Collected: No.
                <br />
                <br />
                <b>
                  Category K: Inferences drawn from other personal information.
                </b>
                <br />
                Examples: Profile reflecting a person's preferences,
                characteristics, psychological trends, predispositions,
                behavior, attitudes, intelligence, abilities, and aptitudes.
                <br />
                <br />
                Collected: No.
                <br />
                <br />
              </Typography>
              <br />
              <br />
              <Typography variant="h6">Your Rights under the CCPA</Typography>
              <br />
              <Typography variant="body2">
                The CCPA provides California residents with specific rights
                regarding their personal information. If You are a resident of
                California, You have the following rights:
                <br />
                <b>The right to notice.</b> You have the right to be notified
                which categories of Personal Data are being collected and the
                purposes for which the Personal Data is being used.
                <br />
                <b>The right to request.</b>Under CCPA, You have the right to
                request that We disclose information to You about Our
                collection, use, sale, disclosure for business purposes and
                share of personal information.
                <br />
                <b>
                  The right to say no to the sale of Personal Data (opt-out)
                </b>
                You have the right to direct Us to not sell Your personal
                information. To submit an opt-out request please contact Us.
                <br />
                <b>The right to delete Personal Data.</b>
                You have the right to request the deletion of Your Personal
                Data, subject to certain exceptions. Once We receive and confirm
                Your request, We will delete (and direct Our Service Providers
                to delete) Your personal information from our records, unless an
                exception applies.
                <b>The right not to be discriminated against.</b>
                You have the right not to be discriminated against for
                exercising any of Your consumer's rights
              </Typography>
              <br />
              <br />
              <Typography variant="h6">Payments</Typography>
              <Typography variant="body2">
                We may provide paid products and/or services within the Service.
                In that case, we use third-party services for payment processing
                (e.g. payment processors).
                <br />
                <br />e will not store or collect your payment card details.
                That information is provided directly to our third-party payment
                processors whose use of your personal information is governed by
                their Privacy Policy. These payment processors adhere to the
                standards set by PCI-DSS as managed by the PCI Security
                Standards Council, which is a joint effort of brands like Visa,
                Mastercard, American Express and Discover. PCI-DSS requirements
                help ensure the secure handling of payment information.
                <br />
                <br />
                The payment processor(s) we work with:
                <br />
                <br />
                Google Play In-App Payments Their Privacy Policy can be viewed
                at
                <br />
                <a
                  rel="noreferrer"
                  href="https://policies.google.com/privacy"
                  target="_blank"
                >
                  https://policies.google.com/privacy
                </a>
              </Typography>
              <br />
              <br />
              <Typography variant="h6">Security Of Data</Typography>
              <Typography variant="body2">
                The security of your data is important to us, but remember that
                no method of transmission over the Internet, or method of
                electronic storage is 100% secure. While we strive to use
                commercially acceptable means to protect your Personal Data, we
                cannot guarantee its absolute security.
              </Typography>
              <br />
              <br />
              <Typography variant="h6">
                Changes to This Privacy Policy
              </Typography>
              <br />
              <Typography variant="body2">
                We may change our Privacy Policy from time to time, and we will
                notify you of any changes by posting updates to this page. These
                changes are effective immediately after they are posted here.
              </Typography>
              <Divider className={classes.divider} />
              <Typography variant="body2">
                *We are not responsible for inappropriate names that has been
                given by your participant in the joining session page or
                joinning via app. It's between you and the participant that you
                shared your session.
              </Typography>
              <Divider className={classes.divider} />
              <Typography variant="body1">Contact</Typography>
              <Typography variant="body1">
                Send mail to: <b>attendance.qr.app@gmail.com</b>
              </Typography>
              <Typography variant="body2"></Typography>
            </Paper>
          </Container>
        </ThemeProvider>

        <a
          className={classes.link}
          rel="noreferrer"
          href="https://play.google.com/store/apps/details?id=com.attendanceqr"
        >
          <img
            className={classes.image}
            alt="Get it on Google Play"
            src={googleButton}
          />
        </a>
      </div>
    </div>
  );
};
